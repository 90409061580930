<template>
  <div>
    <CBox @click="open">
      <CText
        as="button"
        color="#D32737"
        :font-size="['12px', '16px']"
        font-weight="400"
      >
        Ganti Program
      </CText>
    </CBox>

    <c-modal
      :is-open="isOpen"
      :on-close="close"
      size="md"
      is-centered
    >
      <c-modal-content
        ref="content"
        mx="16px"
        py="16px"
        rounded="8px"
      >
        <c-modal-header
          m="auto"
          pb="0px"
        >
          <CImage
            size="150px"
            object-fit="cover"
            :src="imageIllustration"
            alt="Image Confirmation"
          />
        </c-modal-header>
        <c-modal-body
          font-size="20px"
          font-weight="700"
          text-align="center"
          color="#008C81"
        >
          Apa kamu yakin ingin mengganti program?
        </c-modal-body>
        <c-modal-footer
          d="flex"
          gap="16px"
        >
          <c-button
            rounded="1000px"
            variant-color="red"
            font-size="14px"
            font-weight="500"
            d="flex"
            gap="8px"
            w="100%"
            @click="close"
          >
            Batal
            <inline-svg
              :src="iconCircleClose"
              height="19"
              width="19"
              fill="white"
            />
          </c-button>
          <c-button
            variant-color="primary"
            mr="3"
            rounded="1000px"
            font-size="14px"
            font-weight="500"
            d="flex"
            gap="8px"
            w="100%"
            @click="$router.push({ name: 'client.select-program' })"
          >
            Konfirmasi
            <inline-svg
              :src="iconCircleCheck"
              height="19"
              width="19"
              fill="white"
            />
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import iconCircleClose from '@/assets/icons/icon-circle-close.svg'
import iconCircleCheck from '@/assets/icons/icon-circle-check.svg'
import imageIllustration from '@/assets/images/illustration-7-raw.jpeg'

export default {
  data() {
    return {
      isOpen: false,
      iconCircleClose,
      iconCircleCheck,
      imageIllustration,
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
  },
}
</script>