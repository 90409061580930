<template>
  <div>
    <CBox
      as="p"
      :font-size="['12px', '16px']"
      :font-weight="['400', 'Regular']"
      d="flex"
      gap="8px"
      align-items="center"
      cursor="pointer"
      :color="colorCoupon"
      @click="isOpen = true"
    >
      <inline-svg
        :src="iconCircleQuestionmark"
        min-height="14"
        height="14"
        min-width="14"
        width="14"
        :fill="colorCoupon"
      />
      {{ statusAppliedCoupon === 'failed' ? "Kode kupon tidak sesuai" : "Pelajari lebih lanjut" }}
    </CBox>

    <c-drawer
      :is-open="isOpen"
      placement="bottom"
      :on-close="close"
      :initial-focus-ref="()=>$refs.inputInsideModal"
    >
      <c-drawer-overlay />
      <c-drawer-content rounded-top="12px">
        <c-drawer-close-button />
        <c-drawer-header
          m="auto"
          pb="0px"
        >
          <CImage
            size="110px"
            object-fit="cover"
            :src="iconCoupon"
            alt="Segun Adebayo"
          />
        </c-drawer-header>

        <c-drawer-body
          text-align="justify"
          font-size="14px"
          font-weight="500"
          color="#3E3E3E"
        >
          Kamu dapat menggunakan kode kupon untuk memotong harga pesananmu. Kode kupon dapat kamu temukan di halaman promosi ataupun secara eksklusif didapatkan melalui promosi khusus yang diadakan oleh Dietela.
        </c-drawer-body>

        <c-drawer-footer>
          <c-button
            font-size="14px"
            font-weight="500"
            variant-color="primary"
            size="lg"
            rounded="1000px"
            w="100%"
            @click="close"
          >
            Kembali
          </c-button>
        </c-drawer-footer>
      </c-drawer-content>
    </c-drawer>
  </div>
</template>

<script>
import iconCoupon from '@/assets/images/icon-1-raw.png'
import iconCircleQuestionmark from '@/assets/icons/icon-circle-questionmark.svg'

export default {
  props: {
    colorCoupon: {
      type: String,
      default: '',
    },
    statusAppliedCoupon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      iconCoupon,
      iconCircleQuestionmark,
    }
  },
  methods: {
    close() {
      this.isOpen = false
    },
  },
}
</script>