var render = function () {
  var _vm$selectedCart$prod, _vm$selectedCart$prod2, _vm$selectedCart$prod3, _vm$selectedCart$prod4, _vm$selectedCart$prod5, _vm$selectedCart$prod6, _vm$selectedCart, _vm$selectedCart$prod7, _vm$selectedCart2, _vm$selectedCart2$pro;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !!_vm.selectedCart.productService ? _c('CBox', {
    attrs: {
      "w": "100%",
      "bg": ['#F2F2F2', 'white'],
      "h": ['calc(100vh - 64px)', '100%']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Keranjang',
        href: '/cart'
      }, {
        label: 'Periksa Pesanan',
        isCurrent: true
      }]
    }
  })], 1), _c('CText', {
    attrs: {
      "d": ['none', 'block'],
      "text-align": "center",
      "font-family": "Roboto",
      "font-size": "20px",
      "font-weight": "700",
      "mb": "1rem",
      "color": "#333"
    }
  }, [_vm._v(" Periksa Pesanan ")]), _c('CBox', {
    attrs: {
      "d": "flex",
      "w": "100%",
      "flex-dir": ['column', 'row'],
      "px": "16px",
      "gap": "16px"
    }
  }, [_c('CBox', {
    attrs: {
      "bg": ['white', 'white'],
      "width": "100%",
      "p": ['16px', '30px'],
      "mt": "16px",
      "mb": ['316px', '0px'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['none', '12px']
    }
  }, [_c('CBox', {
    attrs: {
      "d": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('CText', {
    attrs: {
      "font-size": ['18px', '20px'],
      "font-weight": ['500', 'Bold']
    }
  }, [_vm._v(" Detail Program ")]), _c('TextChangeProgram')], 1), _c('CDivider'), _c('CBox', {
    attrs: {
      "d": "flex",
      "gap": "8px"
    }
  }, [!!((_vm$selectedCart$prod = _vm.selectedCart.productService) !== null && _vm$selectedCart$prod !== void 0 && (_vm$selectedCart$prod2 = _vm$selectedCart$prod.product) !== null && _vm$selectedCart$prod2 !== void 0 && _vm$selectedCart$prod2.photoUrl) ? _c('CImage', {
    attrs: {
      "object-fit": "cover",
      "src": (_vm$selectedCart$prod3 = _vm.selectedCart.productService) === null || _vm$selectedCart$prod3 === void 0 ? void 0 : (_vm$selectedCart$prod4 = _vm$selectedCart$prod3.product) === null || _vm$selectedCart$prod4 === void 0 ? void 0 : _vm$selectedCart$prod4.photoUrl,
      "alt": "Image Program",
      "h": ['89px', '153px'],
      "w": ['70px', '120px'],
      "rounded": "12px"
    }
  }) : _vm._e(), _c('CBox', [_c('CText', {
    attrs: {
      "font-size": ['18px', '28px'],
      "font-weight": ['500', 'Bold']
    }
  }, [_vm._v(" " + _vm._s((_vm$selectedCart$prod5 = _vm.selectedCart.productService) === null || _vm$selectedCart$prod5 === void 0 ? void 0 : (_vm$selectedCart$prod6 = _vm$selectedCart$prod5.product) === null || _vm$selectedCart$prod6 === void 0 ? void 0 : _vm$selectedCart$prod6.name) + " " + _vm._s((_vm$selectedCart = _vm.selectedCart) === null || _vm$selectedCart === void 0 ? void 0 : (_vm$selectedCart$prod7 = _vm$selectedCart.productService) === null || _vm$selectedCart$prod7 === void 0 ? void 0 : _vm$selectedCart$prod7.programsService) + " (" + _vm._s(((_vm$selectedCart2 = _vm.selectedCart) === null || _vm$selectedCart2 === void 0 ? void 0 : (_vm$selectedCart2$pro = _vm$selectedCart2.productService) === null || _vm$selectedCart2$pro === void 0 ? void 0 : _vm$selectedCart2$pro.duration) * _vm.selectedCart.quantity) + " Hari) ")]), _c('router-link', {
    attrs: {
      "to": _vm.getHandleMore
    }
  }, [_c('CText', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', 'Regular'],
      "mt": ['0px', '8px'],
      "d": "flex",
      "gap": "8px",
      "align-items": "center",
      "cursor": "pointer"
    }
  }, [_vm._v(" Selengkapnya "), _c('CBox', {
    attrs: {
      "d": ['none', 'block']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconArrowRight,
      "height": "12",
      "width": "12",
      "min-height": "12",
      "min-width": "12"
    }
  })], 1)], 1)], 1), _c('CText', {
    attrs: {
      "font-size": ['18px', '20px'],
      "font-weight": ['700', 'Bold'],
      "color": "#008C81",
      "mt": ['0px', '8px']
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.price * _vm.selectedCart.quantity)) + " ")])], 1)], 1), _c('CDivider'), _c('CBox', {
    attrs: {
      "color": "#008C81",
      "d": "flex",
      "flex-dir": "column",
      "gap": "8px",
      "mt": "16px"
    }
  }, [_c('CText', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": ['500', 'Regular'],
      "color": _vm.colorCoupon
    }
  }, [_vm._v(" Kode Kupon ")]), _c('CBox', {
    attrs: {
      "d": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('c-input-group', {
    attrs: {
      "size": "lg",
      "w": "100%"
    }
  }, [_c('c-input-left-element', [_c('inline-svg', {
    attrs: {
      "src": _vm.iconCoupon,
      "height": "16",
      "width": "16",
      "fill": _vm.colorCoupon
    }
  })], 1), _c('CInput', {
    attrs: {
      "rounded": "8px",
      "type": "text",
      "color": _vm.colorCoupon,
      "placeholder": "Masukkan kode kupon",
      "font-size": ['14px', '18px'],
      "font-weight": ['500', 'Medium'],
      "value": _vm.couponCode,
      "disabled": _vm.statusAppliedCoupon === 'success',
      "border-color": _vm.statusAppliedCoupon === 'failed' ? '#D32737' : 'inherit'
    },
    on: {
      "change": _vm.handleChangeCoupon
    }
  })], 1), _c('CButton', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": ['500', 'Medium'],
      "variant-color": _vm.statusAppliedCoupon === 'success' ? 'red' : 'primary',
      "rounded": "1000px",
      "size": "md",
      "px": "20px",
      "disabled": _vm.isLoading.applyCoupon || !_vm.couponCode
    },
    on: {
      "click": _vm.handleCheckCoupon
    }
  }, [_vm._v(" " + _vm._s(_vm.statusAppliedCoupon === 'success' ? "Hapus" : "Terapkan") + " ")])], 1), _c('LearnMore', {
    attrs: {
      "color-coupon": _vm.colorCoupon,
      "status-applied-coupon": _vm.statusAppliedCoupon
    }
  })], 1), _c('CDivider', {
    attrs: {
      "d": ['none', 'block']
    }
  })], 1), _c('CBox', {
    attrs: {
      "mt": "16px",
      "bg": ['white', 'white'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "rounded-top": ['12px', '12px'],
      "rounded-bottom": ['none', '12px'],
      "position": ['fixed', 'unset'],
      "bottom": "0",
      "left": "0",
      "w": "100%",
      "p": ['16px', '30px'],
      "d": "flex",
      "flex-dir": "column",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('CBox', {
    attrs: {
      "w": "100%"
    }
  }, [_c('CText', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": ['500', 'Bold'],
      "color": "#111111",
      "px": "16px"
    }
  }, [_vm._v(" Detail Pembayaran ")]), _c('CDivider'), _c('CBox', {
    attrs: {
      "as": "table",
      "mx": "16px",
      "my": "8px"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "tr"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "th",
      "font-size": ['12px', '16px'],
      "font-weight": ['400', 'Regular'],
      "color": "#555555",
      "text-align": "start",
      "w": "100%"
    }
  }, [_vm._v(" Subtotal ")]), _c('CBox', {
    attrs: {
      "as": "td",
      "font-size": ['14px', '20px'],
      "font-weight": ['400', 'Bold'],
      "color": "#111111",
      "text-align": "end",
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.price * _vm.selectedCart.quantity)) + " ")])], 1), _c('CBox', {
    attrs: {
      "as": "tr"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "th",
      "font-size": ['12px', '16px'],
      "font-weight": ['400', 'Regular'],
      "color": "#555555",
      "text-align": "start",
      "w": "100%"
    }
  }, [_vm._v(" Potongan ")]), _c('CBox', {
    attrs: {
      "as": "td",
      "font-size": ['14px', '20px'],
      "font-weight": ['400', 'Bold'],
      "color": "#111111",
      "text-align": "end",
      "white-space": "nowrap"
    }
  }, [_vm._v(" -" + _vm._s(_vm.formatCurrency(_vm.discount)) + " ")])], 1)], 1), _c('CDivider'), _c('CBox', {
    attrs: {
      "d": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "my": "20px"
    }
  }, [_c('CBox', {
    attrs: {
      "color": "#888888",
      "font-size": ['12px', '18px'],
      "font-weight": ['400', 'Medium']
    }
  }, [_vm._v(" Total ")]), _c('CBox', {
    attrs: {
      "font-size": ['18px', '28px'],
      "font-weight": ['700', 'Bold'],
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceWithDiscount)) + " ")])], 1), _c('CButton', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": ['500', 'Regular'],
      "variant-color": "primary",
      "mt": "auto",
      "w": "100%",
      "rounded": "1000px",
      "size": "lg",
      "disabled": _vm.isLoading.checkout
    },
    on: {
      "click": _vm.handleCheckout
    }
  }, [_vm._v(" Lakukan Pembayaran ")])], 1)], 1)], 1), _c('ModalCouponInvalid', {
    attrs: {
      "is-open": _vm.isModalOpen.ModalCouponInvalid
    },
    on: {
      "handleClose": _vm.handleCloseModal
    }
  }), _c('ModalErrorProgramCannotPurchase', {
    attrs: {
      "is-open": _vm.isModalOpen.ModalErrorProgramCannotPurchase
    },
    on: {
      "handleClose": _vm.handleCloseModal
    }
  }), _c('ModalErrorProgramCannotPurchaseKonsultation', {
    attrs: {
      "is-open": _vm.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation
    },
    on: {
      "handleClose": _vm.handleCloseModal
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }