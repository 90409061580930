<template>
  <CBox
    v-if="!!selectedCart.productService"
    w="100%"
    :bg="['#F2F2F2', 'white']"
    :h="['calc(100vh - 64px)', '100%']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Keranjang', href: '/cart' },
          { label: 'Periksa Pesanan',isCurrent: true },
        ]"
      />
    </Portal>

    <CText
      :d="['none', 'block']"
      text-align="center"
      font-family="Roboto"
      font-size="20px"
      font-weight="700"
      mb="1rem"
      color="#333"
    >
      Periksa Pesanan
    </CText>
    <CBox
      d="flex"
      w="100%"
      :flex-dir="['column', 'row']"
      px="16px"
      gap="16px"
    >
      <CBox
        :bg="['white', 'white']"
        width="100%"
        :p="['16px', '30px']"
        mt="16px"
        :mb="['316px', '0px']"
        :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
        :border-radius="['none', '12px']"
      >
        <CBox
          d="flex"
          justify-content="space-between"
          align-items="center"
        >
          <CText
            :font-size="['18px', '20px']"
            :font-weight="['500', 'Bold']"
          >
            Detail Program
          </CText>
          <TextChangeProgram />
        </CBox>
        <CDivider />
        <CBox
          d="flex"
          gap="8px"
        >
          <CImage
            v-if="!!selectedCart.productService?.product?.photoUrl"
            object-fit="cover"
            :src="selectedCart.productService?.product?.photoUrl"
            alt="Image Program"
            :h="['89px', '153px']"
            :w="['70px', '120px']"
            rounded="12px"
          />
          <CBox>
            <CText
              :font-size="['18px', '28px']"
              :font-weight="['500', 'Bold']"
            >
              {{ selectedCart.productService?.product?.name }}
              {{ selectedCart?.productService?.programsService }}
              ({{ selectedCart?.productService?.duration * selectedCart.quantity }} Hari)
            </CText>

            <router-link
              :to="getHandleMore"
            >
              <CText
                :font-size="['12px', '16px']"
                :font-weight="['400', 'Regular']"
                :mt="['0px', '8px']"
                d="flex"
                gap="8px"
                align-items="center"
                cursor="pointer"
              >
                Selengkapnya
                <CBox :d="['none', 'block']">
                  <inline-svg
                    :src="iconArrowRight"
                    height="12"
                    width="12"
                    min-height="12"
                    min-width="12"
                  />
                </CBox>
              </CText>
            </router-link>
            <CText
              :font-size="['18px', '20px']"
              :font-weight="['700', 'Bold']"
              color="#008C81"
              :mt="['0px', '8px']"
            >
              {{ formatCurrency(price * selectedCart.quantity) }}
            </CText>
          </CBox>
        </CBox>
        <CDivider />
        <CBox
          color="#008C81"
          d="flex"
          flex-dir="column"
          gap="8px"
          mt="16px"
        >
          <CText
            :font-size="['14px', '16px']"
            :font-weight="['500', 'Regular']"
            :color="colorCoupon"
          >
            Kode Kupon
          </CText>
          <CBox
            d="flex"
            gap="8px"
            align-items="center"
          >
            <c-input-group
              size="lg"
              w="100%"
            >
              <c-input-left-element>
                <inline-svg
                  :src="iconCoupon"
                  height="16"
                  width="16"
                  :fill="colorCoupon"
                />
              </c-input-left-element>
              <CInput
                rounded="8px"
                type="text"
                :color="colorCoupon"
                placeholder="Masukkan kode kupon"
                :font-size="['14px', '18px']"
                :font-weight="['500', 'Medium']"
                :value="couponCode"
                :disabled="statusAppliedCoupon === 'success'"
                :border-color="statusAppliedCoupon === 'failed' ? '#D32737' : 'inherit'"
                @change="handleChangeCoupon"
              />
            </c-input-group>
            <CButton
              :font-size="['14px', '18px']"
              :font-weight="['500', 'Medium']"
              :variant-color="statusAppliedCoupon === 'success' ? 'red' : 'primary'"
              rounded="1000px"
              size="md"
              px="20px"
              :disabled="isLoading.applyCoupon || !couponCode"
              @click="handleCheckCoupon"
            >
              {{ statusAppliedCoupon === 'success' ? "Hapus" : "Terapkan" }}
            </CButton>
          </CBox>
          <LearnMore
            :color-coupon="colorCoupon"
            :status-applied-coupon="statusAppliedCoupon"
          />
        </CBox>
        <CDivider :d="['none', 'block']" />
      </CBox>

      <CBox
        mt="16px"
        :bg="['white', 'white']"
        :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
        :rounded-top="['12px', '12px']"
        :rounded-bottom="['none', '12px']"
        :position="['fixed', 'unset']"
        bottom="0"
        left="0"
        w="100%"
        :p="['16px', '30px']"
        d="flex"
        flex-dir="column"
        align-items="center"
        justify-content="center"
      >
        <CBox
          w="100%"
        >
          <CText
            :font-size="['16px', '20px']"
            :font-weight="['500', 'Bold']"
            color="#111111"
            px="16px"
          >
            Detail Pembayaran
          </CText>
          <CDivider />
          <CBox
            as="table"
            mx="16px"
            my="8px"
          >
            <CBox as="tr">
              <CBox
                as="th"
                :font-size="['12px', '16px']"
                :font-weight="['400', 'Regular']"
                color="#555555"
                text-align="start"
                w="100%"
              >
                Subtotal
              </CBox>
              <CBox
                as="td"
                :font-size="['14px', '20px']"
                :font-weight="['400', 'Bold']"
                color="#111111"
                text-align="end"
                white-space="nowrap"
              >
                {{ formatCurrency(price * selectedCart.quantity) }}
              </CBox>
            </CBox>
            <CBox as="tr">
              <CBox
                as="th"
                :font-size="['12px', '16px']"
                :font-weight="['400', 'Regular']"
                color="#555555"
                text-align="start"
                w="100%"
              >
                Potongan
              </CBox>
              <CBox
                as="td"
                :font-size="['14px', '20px']"
                :font-weight="['400', 'Bold']"
                color="#111111"
                text-align="end"
                white-space="nowrap"
              >
                -{{ formatCurrency(discount) }}
              </CBox>
            </CBox>
          </CBox>
          <CDivider />
          <CBox
            d="flex"
            justify-content="space-between"
            align-items="center"
            my="20px"
          >
            <CBox
              color="#888888"
              :font-size="['12px', '18px']"
              :font-weight="['400', 'Medium']"
            >
              Total
            </CBox>
            <CBox
              :font-size="['18px', '28px']"
              :font-weight="['700', 'Bold']"
              color="#008C81"
            >
              {{ formatCurrency(priceWithDiscount) }}
            </CBox>
          </CBox>
          <CButton
            :font-size="['14px', '16px']"
            :font-weight="['500', 'Regular']"
            variant-color="primary"
            mt="auto"
            w="100%"
            rounded="1000px"
            size="lg"
            :disabled="isLoading.checkout"
            @click="handleCheckout"
          >
            Lakukan Pembayaran
          </CButton>
        </CBox>
      </CBox>
    </CBox>

    <!-- MODAL -->
    <ModalCouponInvalid
      :is-open="isModalOpen.ModalCouponInvalid"
      @handleClose="handleCloseModal"
    />
    <ModalErrorProgramCannotPurchase
      :is-open="isModalOpen.ModalErrorProgramCannotPurchase"
      @handleClose="handleCloseModal"
    />
    <ModalErrorProgramCannotPurchaseKonsultation
      :is-open="isModalOpen.ModalErrorProgramCannotPurchaseKonsultation"
      @handleClose="handleCloseModal"
    />
  </CBox>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { Portal } from 'portal-vue'
import { CBox, CText } from '@chakra-ui/vue'
import iconCoupon from '@/assets/icons/icon-coupon.svg'
import { formatCurrency } from '@/utils/format-currency'
import LearnMore from '@/views/client/cart/check-order/_widgets/learn-more.vue'
import iconArrowRight from '@/assets/icons/icon-arrow-right.svg'
import TextChangeProgram from '@/views/client/cart/check-order/_widgets/text-change-program.vue'
import ModalCouponInvalid from '@/views/client/cart/check-order/_widgets/modal-coupon-invalid.vue'
import ModalErrorProgramCannotPurchase from '@/views/client/cart/check-order/_widgets/modal-error-program-cannot-purchase.vue'
import ModalErrorProgramCannotPurchaseKonsultation from '@/views/client/cart/check-order/_widgets/modal-error-program-cannot-purchase-konsultation.vue'

export default {
  components: {
    LearnMore,
    BreadcrumbPath,
    Portal,
    CBox,
    CText,
    TextChangeProgram,
    ModalCouponInvalid,
    ModalErrorProgramCannotPurchase,
    ModalErrorProgramCannotPurchaseKonsultation,
  },
  data() {
    return {
      couponCode: '',
      selectedItem: {},
      iconCoupon,
      couponDetail: {
        id: '',
        type: '',
        totalDiscount: 0,
        isValid: null,
      },
      isLoading: {
        applyCoupon: false,
        checkout: false,
      },
      isModalOpen: {
        ModalCouponInvalid: false,
        ModalErrorProgramCannotPurchase: false,
        ModalErrorProgramCannotPurchaseKonsultation: false,
      },
      iconArrowRight,
    }
  },
  computed: {
    ...mapGetters({
      selectedCart: 'cart/selectedCart',
      userType: 'userType/userType',
    }),
    getHandleMore() {
      if (this.selectedCart?.productService?.isKonsultasi === 1) {
        return {
          name: 'client.program.consultation',
        }
      }
      if (this.selectedCart?.productService?.isExtend === 1) {
        return {
          name: 'client.program.extend',
        }
      }
      return {
        name: 'client.program.detail',
        params: { id: this.selectedCart?.productService?.product?.slug ? this.selectedCart?.productService?.product?.slug : this.selectedCart?.productId },
      }
    },
    price() {
      return this.selectedCart?.productService?.discountedPrice || this.selectedCart?.productService?.price
    },
    discount() {
      if (this.couponDetail.type === 'percent') {
        return (this.couponDetail.totalDiscount / 100) * (this.price * this.selectedCart.quantity)
      }
      return this.couponDetail.totalDiscount
    },
    priceWithDiscount() {
      return this.price * this.selectedCart.quantity - this.discount
    },
    colorCoupon() {
      if (this.statusAppliedCoupon === 'not-applied') {
        return '#888888'
      } else if (this.statusAppliedCoupon === 'success') {
        return '#008C81'
      } else if (this.statusAppliedCoupon === 'failed') {
        return '#D32737'
      }
      return '#888888'
    },
    statusAppliedCoupon() {
      if (this.couponDetail.isValid === null) {
        return 'not-applied'
      } else if (this.couponCode && this.couponDetail.isValid) {
        return 'success'
      } else if (!(this.couponCode && this.couponDetail.isValid)) {
        return 'failed'
      }
      return 'not-applied'
    },
  },
  async mounted() {
    if (!this.selectedCart?.productService) {
      this.$router.push({ name: 'client.cart' })
      return
    }
    const res = await this.getProductServiceId(this.selectedCart?.productService?.id)

    this.setSelectedCart({
      ...this.selectedCart,
      productService: {
        id: res.data.id,
        programsService: res.data.programsService,
        duration: res.data.duration,
        price: res.data.price,
        isExtend: res.data.isExtend,
        discountedPrice: res.data.discountedPrice,
        productId: res.data.productId,
        isKonsultasi: res.data.isKonsultasi,
        product: {
          photoUrl: res.data.product.photoUrl,
          name: res.data.product.name,
          slug: null,
        },
      },
    })
    this.triggerUpdateUserType()
  },
  methods: {
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
      setCouponId: 'generalPersist/setCouponId',
    }),
    ...mapActions({
      getProductServiceId: 'general/getProductServiceId',
      fetchListCart: 'cart/list',
      detailProduct: 'clients/getProductById',
      onOpenModal: 'clients/onToggleModal',
      reqCheckCoupon: 'clients/reqCheckCoupon',
      reqCheckout: 'clients/reqCheckout',
      triggerUpdateUserType: 'userType/triggerUpdateUserType',
    }),
    onDetailProduct(item) {
      this.$router.push({ name: 'client.index', hash: '#program' })
      this.detailProduct(item.productId).then(() => this.onOpenModal(true))
    },
    resetCouponDetail() {
      this.couponDetail = {
        id: '',
        type: '',
        totalDiscount: 0,
        isValid: null,
      }
    },
    formatCurrency,
    handleChangeCoupon(e) {
      if (this.couponDetail.isValid !== null) {
        this.resetCouponDetail()
      }
      this.couponCode = e.target.value
    },
    async handleCheckCoupon() {
      if (this.statusAppliedCoupon === 'success') {
        this.couponCode = ''
        this.resetCouponDetail()
        return
      }

      this.isLoading.applyCoupon = true
      try {
        const res = await this.reqCheckCoupon({
          couponCode: this.couponCode,
          productServiceId: this.selectedCart?.productService?.id,
        })
        this.couponDetail = res.data
        this.couponDetail.isValid = true
      } catch (e) {
        this.isModalOpen.ModalCouponInvalid = true
        this.couponDetail.isValid = false
        console.error(e)
      }
      this.isLoading.applyCoupon = false
    },
    async handleCheckout() {
      try {
        this.isLoading.checkout = true
        this.setCouponId(this.couponDetail.id)
        const res = await this.reqCheckout({
          cartId: this.selectedCart.id,
          productServiceId: this.selectedCart?.productService?.id,
          couponId: this.couponDetail.id,
          qty: this.selectedCart.quantity,
        })

        // POSTHOG capture `order created`
        const selectedProgram = {
          productService: this.selectedCart,
          coupon: this.couponDetail.isValid ? this.couponDetail : null,
          qty: this.selectedCart.quantity,
        }
        this.$posthog.capture('order created', {
          userType: this.userType ?? 'not-registered',
          program: selectedProgram,
        })
        window.fbq('track', 'CheckOut')

        this.$router.replace({
          name: 'client.checkout.payment',
          params: { id: res.data?.id },
        })
        await this.fetchListCart()
      } catch (e) {
        if (this.selectedCart?.productService?.isKonsultasi === 1) {
          this.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation = true
        } else {
          this.isModalOpen.ModalErrorProgramCannotPurchase = true
        }
      } finally {
        this.isLoading.checkout = false
      }
    },
    handleCloseModal(type = 'ModalCouponInvalid') {
      if (type === 'ModalCouponInvalid') {
        this.isModalOpen.ModalCouponInvalid = false
      } else if (type === 'ModalErrorProgramCannotPurchase' && this.selectedCart?.productService?.isKonsultasi === 1) {
        this.isModalOpen.ModalErrorProgramCannotPurchaseKonsultation = false
      } else if (type === 'ModalErrorProgramCannotPurchase') {
        this.isModalOpen.ModalErrorProgramCannotPurchase = false
      }
    },
  },
}
</script>
